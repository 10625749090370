export const initCompC0002 = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const containers = document.querySelectorAll('.c0002-image.blur');

        containers.forEach(container => {
            const imageUrl = container.querySelector('.main-image').src;

            const style = document.createElement("style");
            style.innerHTML = `
                .c0002-image.blur::before {
                    background-image: url("${imageUrl}");
                }
            `;
            document.head.appendChild(style);
        });
    });
};

initCompC0002();
export const initCompC0003 = () => {

    document.addEventListener("DOMContentLoaded", function () {

        const navWrappers = document.querySelectorAll(".c0003-tabs.nav-wrapper");

        navWrappers.forEach((navWrapper) => {
            const nav = navWrapper.querySelector(".c0003-tabs-nav");
            const navContainer = navWrapper.querySelector(".c0003-tabs .nav-container");
            const navLinks = navContainer.querySelectorAll(".c0003-tabs .nav-link");
            const lastNavLink = navLinks[navLinks.length - 1];

            function checkOverflow() {
                const isOverflowing = nav.scrollWidth > navWrapper.clientWidth;
                const lastLinkRect = lastNavLink.getBoundingClientRect();
                const containerRect = navWrapper.getBoundingClientRect();
                if (isOverflowing) {
                    if (lastLinkRect.right <= containerRect.right) {
                        navWrapper.classList.remove("overflowing");
                    } else {
                        navWrapper.classList.add("overflowing");
                    }
                } else {
                    navWrapper.classList.remove("overflowing");
                }
            }

            // Function to scroll the nav container to make the focused element fully visible
            function scrollToElement(element) {
                const elementRect = element.getBoundingClientRect();
                const containerRect = navWrapper.getBoundingClientRect();

                if (elementRect.right > containerRect.right) {
                    navContainer.scrollLeft += elementRect.right - containerRect.right;
                } else if (elementRect.left < containerRect.left) {
                    navContainer.scrollLeft -= containerRect.left - elementRect.left;
                }
            }

            // Add focus event listeners to each nav link
            navLinks.forEach((link) => {
                link.addEventListener("focus", () => scrollToElement(link));
                checkOverflow();
            });

            // Check overflow on resize
            window.addEventListener("resize", checkOverflow);

            // Check overflow on scroll
            navContainer.addEventListener("scroll", checkOverflow);

            // Check overflow on initial load
            checkOverflow();
        });
    });
};

initCompC0003();



export const initCompC0013 = () => {

    document.addEventListener('DOMContentLoaded', function () {
        $(".c0013-readmore").each(function () {
            var component = $(this).closest(".c0013-readmore");
            var content = $(this).find(".read-more-content");
            var inner = content.find(".read-more-content-inner");
            var toggler = component.find(".read-more-toggler");

            function initializeReadMore() {
                content.data("height", content.height());
                content.css("max-height", content.outerHeight());

                var togglerText = toggler.find("span");
                toggler.data("text-more", "Leer más");
                toggler.data("text-less", "Leer menos");

                var classes = content.attr("class");
                var match = classes.match(/ellipsis-(\d+)/);
                if (match) {
                    var lines = match[1];
                    content.data("lines", lines);
                }

                content.removeClass("ellipsis-" + lines);
                content.data("expanded-height", inner.outerHeight());
                content.addClass("ellipsis-" + lines);

                if (content.data("height") >= content.data("expanded-height")) {
                    toggler.hide();
                    content.removeClass("ellipsis-" + lines);
                    content.addClass("expanded");
                } else {
                    togglerText.html(toggler.data("text-more"));
                    content.addClass("ellipsis-" + lines).removeClass("expanded");
                    toggler.show();
                }
            }

            initializeReadMore();

            $(window).resize(function () {
                initializeReadMore();
            });
        });

        $(".read-more-toggler")
            .unbind("click")
            .click(function (e) {
                e.preventDefault();
                var component = $(this).closest(".c0013-readmore");
                var content = component.find(".read-more-content");
                var toggler = component.find(".read-more-toggler");
                var togglerText = toggler.find("span");

                var classes = content.attr('class').split(/\s+/);
                var stepLines = 30

                if (!content.hasClass("expanded")) {
                    var ellipsisClass = classes.find(function (className) {
                        return className.startsWith('ellipsis-');
                    });

                    var lines = ellipsisClass.split('-')[1];
                    content.removeClass("ellipsis-" + lines).addClass("ellipsis-" + ((lines * 1) + stepLines))
                    content.css("max-height", '');
                    setTimeout(function(){

                        if ((content.outerHeight() * 1.1) >= content.data("expanded-height")) {
                            content.removeClass("ellipsis-" + ((lines * 1) + stepLines)).addClass("expanded");
                            toggler.addClass("expanded");
                            togglerText.html(toggler.data("text-less"));
                            content.css("max-height", content.data("expanded-height"));
                        }
                    }, 100)

                } else {
                    togglerText.html(toggler.data("text-more"));
                    content.css("max-height", content.data("height"));
                    toggler.removeClass("expanded");
                    setTimeout(() => {
                        content.addClass("ellipsis-" + content.data("lines")).removeClass("expanded");
                    }, 500);
                }
            });
    })

}

initCompC0013();
export const initCompC0016 = () => {

    document.addEventListener("DOMContentLoaded", function () {
        var swiper = new Swiper(".mySwiper", {
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
            centeredSlides: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
            },
            autoHeight: true,
            spaceBetween: 20,
        });

        function setEqualHeight() {
            let maxHeight = 0;
            const slides = document.querySelectorAll(".swiper-slide .col");

            slides.forEach((slide) => {
                slide.style.height = "auto"; // Reset height
                const slideHeight = slide.clientHeight;
                if (slideHeight > maxHeight) {
                    maxHeight = slideHeight;
                }
            });

            slides.forEach((slide) => {
                slide.style.height = `${maxHeight}px`;
            });
        }

        // Set equal height on initial load
        setEqualHeight();

        // Set equal height on slide change (for dynamic content)
        swiper.on("slideChange", function () {
            setEqualHeight();
        });

        // Set equal height on window resize
        window.addEventListener("resize", function () {
            setEqualHeight();
        });
    });

};

initCompC0016();

export const initCompC0019 = () => {

    // Manejo de la visibilidad de la contraseña
  const togglesPassword = document.querySelectorAll(".togglePassword");
  console.log(togglesPassword)
  if (togglesPassword) {
    togglesPassword.forEach(function (togglePassword) {
      togglePassword.onclick = function () {
        console.log('click')
        const passwordInput = this.parentNode.querySelector(
          "input[type='password'], input[type='text']"
        );
        console.log(passwordInput)
        if (passwordInput) {

          const currentType = passwordInput.getAttribute("type");

          const newType = currentType === "password" ? "text" : "password";
          passwordInput.setAttribute("type", newType);

          this.setAttribute(
            "title",
            newType === "password"
              ? "Mostrar contraseña"
              : "Ocultar contraseña"
          );
          this.classList.toggle("showing");
        } else {
          console.error("El campo de contraseña no se encontró.");
        }
      }
    });
  }

  // Autosize para textarea
  function autosizeTextArea(textArea) {
    if (textArea) {
      textArea.style.height = "0px";
      const scrollHeight = textArea.scrollHeight;

      textArea.style.height = scrollHeight + "px";
    }
  }

  const textAreas = document.querySelectorAll("textarea");
  textAreas.forEach((textArea) => {
    autosizeTextArea(textArea);

    textArea.addEventListener("input", function () {
      autosizeTextArea(textArea);
    });
  });

  // Función para cambiar el color del label en switch
  const switchInput = document.getElementById('switch1');
  const label = document.querySelector('.form-check-label');

  if (switchInput)
    if (switchInput.disabled) {
      label.classList.add('disabled-label');
    }

  // Función para el conteo de caracteres
  function updateRemainingCharacters(event) {
    const textarea = event.target;
    const maxLength = textarea.maxLength;
    const remainingChars = maxLength - textarea.value.length;
    const feedbackElement = textarea.parentNode.querySelector(".form-text");

    if (feedbackElement && feedbackElement.classList.contains("form-text")) {
      feedbackElement.textContent = `Quedan ${remainingChars} caracteres`;
    }
  }

  function initCharacterCount(selector) {
    const textareas = document.querySelectorAll(selector);
    textareas.forEach((textarea) => {
      textarea.addEventListener("input", updateRemainingCharacters);
      updateRemainingCharacters({ target: textarea });
    });
  }

  initCharacterCount("textarea.char-count");

  // Manejo de placeholder en los inputs
  const inputs = document.querySelectorAll(
    'input[type="text"], input[type="password"]'
  );

  inputs.forEach((input) => {
    const handlePlaceholder = () => {
      input.placeholder = input.value ? "" : input.dataset.placeholder;
    };

    input.dataset.placeholder = input.placeholder;

    input.addEventListener("input", handlePlaceholder);
    input.addEventListener("blur", handlePlaceholder);
    input.addEventListener("focus", handlePlaceholder);

    handlePlaceholder();
  });

};

document.addEventListener('DOMContentLoaded', function() {
  initCompC0019();
})

export const initCompC0019select = () => {
  document.addEventListener("DOMContentLoaded", function () {
    $("#select1").each(function () { //TODO - ESTO NO VA A FUNCIONAR PARA TODOS LOS SELECTS!!!

      const addPlaceholderClass = (selectElement) => {
        const elements = document.querySelectorAll(".token.tag.attr-value");
        let targetElement = null;
        if (elements.length > 0) {
          for (const element of elements) {
            if (element.textContent.includes("form-control")) {
              targetElement = element;
              break;
            }
          }
        }
        if (selectElement.value === "") {
          selectElement.classList.add("placeholder");
          if (
            targetElement &&
            !targetElement.textContent.includes("placeholder")
          ) {
            targetElement.textContent += " placeholder";
          }
        } else {
          selectElement.classList.remove("placeholder");
          if (
            targetElement &&
            targetElement.textContent.includes("placeholder")
          ) {
            targetElement.textContent = targetElement.textContent.replace(
              " placeholder",
              ""
            );
          }
        }
      };

      const selectElement = document.querySelector("#select1");

      selectElement.addEventListener("change", (event) => {
        addPlaceholderClass(event.target);
      });

      addPlaceholderClass(selectElement);
    });
  });
};

initCompC0019select();

export const initCompTextareaC0019 = () => {

    // document.addEventListener("DOMContentLoaded", function () {

        function autosizeTextArea(textArea) {
            if (textArea) {
                textArea.style.height = "0px";
                const scrollHeight = textArea.scrollHeight;

                textArea.style.height = scrollHeight + "px";
            }
        }

        const textAreas = document.querySelectorAll("textarea");

        textAreas.forEach((textArea) => {
            autosizeTextArea(textArea);

            textArea.addEventListener("input", function () {
                autosizeTextArea(textArea);
            });
        });

        // Función para el conteo de caracteres
        function updateRemainingCharacters(event) {
            const textarea = event.target;
            const maxLength = textarea.maxLength;
            const remainingChars = maxLength - textarea.value.length;
            const feedbackElement = textarea.parentNode.querySelector(".form-text");

            if (feedbackElement && feedbackElement.classList.contains("form-text")) {
                feedbackElement.textContent = `Quedan ${remainingChars} caracteres`;
            }
        }

        function initCharacterCount(selector) {
            const textareas = document.querySelectorAll(selector);

            textareas.forEach((textarea) => {
                textarea.addEventListener("input", updateRemainingCharacters);
                updateRemainingCharacters({target: textarea});
            });
        }

        initCharacterCount("textarea.char-count");
    // });
};

// initCompTextareaC0019();
  
export const initCompC0020 = (id, handleExternalClose = () => {}) => {
    let toastElement
    if (id)
        toastElement = document.getElementById(id);
    else
        toastElement = document.querySelector('.toast');

    console.log("toastElement" + toastElement);

    if (toastElement) {
        const toast = new bootstrap.Toast(toastElement, {
            animation: true,
            autohide: true,
            delay: 4000
        });

        toast.show();
    
        toastElement.addEventListener('hidden.bs.toast', function () {
            handleExternalClose();
        });

    }
}

export const initCompC0024 = () => {
    document.addEventListener("DOMContentLoaded", function () {
        const nav = document.querySelector(".nav-desktop-secondary");
        const sentinel = document.getElementById("header_sentinel");

        const observer = new IntersectionObserver(

            ([entry]) => {
              if (!entry.isIntersecting) {
                nav.classList.add("sticky");
              } else {
                nav.classList.remove("sticky");
              }
            },
            { rootMargin: "0px 0px 0px 0px", threshold: 0 }
        );

        observer.observe(sentinel);


        const dismissMenus = document.querySelectorAll(".c0024-header .dismiss-menu");

        dismissMenus.forEach(function(dismissMenu) {

            dismissMenu.addEventListener("click", function (event) {
                const dropdownHolder = event.target.closest('.dropdown-holder');

                if (dropdownHolder) {
                    dropdownHolder.style.display = "none";
                    document.querySelector(".m-listLogos").focus();
                    setTimeout(function() {
                        dropdownHolder.style.display = "";
                    }, 100);
                }
            });
        });

        if (document.getElementById('header_search_button')) {
            document.getElementById('header_search_button').addEventListener('click', function () {

                var hiddenElement = this.parentNode.querySelector('.d-none');

                if (hiddenElement) {
                    hiddenElement.classList.remove('d-none'); // Eliminar la clase 'd-none'
                }
                document.querySelector('.header-actions .search-form .form-control').focus();

                this.classList.add('d-none');
            });
        }

        if (document.querySelector('#menu-mobile .btn-menu-mobile-close')) {
            document.querySelector('#menu-mobile .btn-menu-mobile-close').addEventListener('click', function() {
                document.getElementById('menu-mobile').classList.remove('show');

                document.querySelectorAll('.m-menuButton').forEach(function(menuButton) {
                    menuButton.setAttribute('aria-expanded', 'false');
                });
            });
        }


    })
};

initCompC0024();

export const initCompC0025 = () => {
  document.addEventListener("DOMContentLoaded", function () {
    const dropdownItems = document.querySelectorAll(".nav-item.dropdown");

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const targetElement = mutation.target;

          const icon = targetElement.querySelector(
            ".nav-link i, .dropdown-item.has-submenu i"
          );

          if (icon) {
            if (targetElement.classList.contains("show")) {
              icon.classList.add("icon-rotate");
            } else {
              icon.classList.remove("icon-rotate");
            }
          }
        }
      });
    });

    dropdownItems.forEach((item) => {
      observer.observe(item, { attributes: true });
    });

    const subMenus = document.querySelectorAll(".dropdown-item.has-submenu");

    subMenus.forEach((subMenu) => {
      subMenu.addEventListener("click", function (e) {
        const link = this.querySelector("a");

        if (link !== null && e.target.closest("a") === link) {
          return;
        }

        e.preventDefault();

        const siblingSubMenu = this.nextElementSibling;

        if (siblingSubMenu) {
          const isExpanded = this.getAttribute("aria-expanded") === "true";

          siblingSubMenu.classList.toggle("sub-item-show");

          this.setAttribute("aria-expanded", !isExpanded);

          const icon = this.querySelector("i");
          if (icon) {
            icon.classList.toggle("icon-rotate");
          }
        }

        e.stopPropagation();
      });
    });

    const navLinks = document.querySelectorAll(".nav-link");

    navLinks.forEach((link) => {
      link.addEventListener("click", function (e) {
        const icon = this.querySelector("i");
        const span = this.querySelector("span");

        if (e.target === span && this.href) {
          window.location.href = this.href;
          
        }

        if (e.target === icon) {
          e.preventDefault();
        }
      });
    });
  });
};

export const initCompC0026 = () => {

  document.addEventListener("DOMContentLoaded", function () {

    var goTop = $('#go-top');
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 200) {
        goTop.fadeIn();
      } else {
        goTop.fadeOut();
      }
    });

    goTop.click(function () {
      $('html, body').stop().animate(
          {
            scrollTop: 0,
          },
          500
      );
    });

  });

}

initCompC0026();
export const initCompC0030 = () => {

  document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelectorAll('div.c0030-tree-filter').length) {
      const togglers = document.getElementsByClassName('c0030-tree-filter__branch');

      for (let i = 0; i < togglers.length; i++) {
        togglers[i].addEventListener('click', function () {
          this.parentElement.querySelector('.c0030-tree-filter__nested').classList.toggle('active');
          this.classList.toggle('c0030-tree-filter__branch-down');
        });
      }
    }
  })
};

initCompC0030();

export const initCompC0033 = function () {

    document.addEventListener("DOMContentLoaded", function () {

        var btn = document.getElementById('filtrar-btn');
        var closeBtn = document.getElementById('close-btn');
        var wrapper = document.querySelector('.c0033-contentlist__listwrap');
        var isMobile = window.matchMedia('(max-width: 768px)').matches;

        if (wrapper) {
            btn.addEventListener('click', function () {
                if (isMobile) {
                    wrapper.classList.toggle('filter-modal-active');
                } else {
                    wrapper.classList.toggle('filter-active');

                    setTimeout(function () {
                        btn.classList.toggle('active');
                    }, 500);
                }

            });

            closeBtn.addEventListener('click', function () {
                wrapper.classList.remove('filter-modal-active');
            });
        }

    });
}

initCompC0033();


export const initCompC0034 = () => {
  document.addEventListener('DOMContentLoaded', function () {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  });
};

initCompC0034();